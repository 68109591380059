.navbar-customer {
    background-color: #BA3B0A;
    color: white;
}

.navbar-business {
    background-color: #102E44;
    color: #B2FCFB;
}

.rbc-time-slot {
    z-index: 1;
}

.rbc-time-slot:hover {
    background-color: lightblue;
}

.client-row:hover {
    background-color: #EEEEEE;
    cursor: pointer;
}

.business-card:hover {
    background-color: #EEEEEE;
    cursor: pointer;
}
